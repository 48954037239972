"use client";
import {ChevronLeftIcon, ChevronRightIcon} from "@radix-ui/react-icons";
import {
  Button,
  Checkbox,
  Container,
  Flex,
  Grid,
  Separator,
  Text,
} from "@radix-ui/themes";
import {usePathname, useRouter, useSearchParams} from "next/navigation";
import {useCallback, useEffect, useState} from "react";
import {useLocalStorage} from "usehooks-ts";
import CreatorPicker from "./CreatorPicker";
import LanguagePicker from "./LanguagePicker";
import VideoRow from "./VideoRow";

type Props = {
  creators: {
    image: string;
    id: string;
    name: string;
  }[];
  videos: {
    videoId: string;
    title: string;
    publishedAt: Date;
    thumbnailUrl: string;
    videoLength: number;
    langId: string | null;
    status: "needs_review" | "reviewed" | "accepted" | "pending" | null;
    tags: string[];
    draftId: string | null;
    draftCreatedAt: Date | null;
  }[];
  langId: string;
  creatorId: string;
  user: {
    id: string;
    languages: string[];
    defaultLangId: string | null;
    defaultCreatorId: string | null;
    following: {
      creatorId: string;
      userId: string;
      creator: {
        image: string;
        id: string;
        name: string;
      };
    }[];
  };
  allTags: string[];
  matchedVideoCount: number;
  pageSize: number;
};

const TranslatePage = ({
  creators,
  videos,
  langId,
  creatorId,
  user,
  allTags,
  matchedVideoCount,
  pageSize,
}: Props) => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();
  const [statusFilter, setStatusFilter] = useState(
    searchParams
      .get("state")
      ?.trim()
      .split(",")
      .filter((s) => s.length) || ["needs_review"],
  );
  const [tagFilter, setTagFilter] = useState(
    searchParams.get("tags")?.split(",") || [],
  );
  const page = Math.max(1, parseInt(searchParams.get("page") ?? "1") || 1) - 1;

  // Store the searchParams in localstorage so the back link on a video page
  // comes back here with the same filters applied.
  const [, setTranslationBack] = useLocalStorage(
    "translationBack",
    `/?${searchParams.toString()}`,
  );
  useEffect(() => {
    setTranslationBack(`/?${searchParams.toString()}`);
  }, [searchParams, setTranslationBack]);

  // Get a new searchParams string by merging the current
  // searchParams with a provided key/value pair
  const createQueryString = useCallback(
    (name: string, value: string | null) => {
      const params = new URLSearchParams(searchParams.toString());
      if (value == null) {
        params.delete(name);
      } else {
        params.set(name, value);
      }

      return params.toString();
    },
    [searchParams],
  );

  const lastPage = Math.max(0, Math.floor((matchedVideoCount - 1) / pageSize));
  if (page > lastPage) {
    if (lastPage === 0) {
      setTimeout(() =>
        router.replace(pathname + "?" + createQueryString("page", null)),
      );
    } else {
      setTimeout(() =>
        router.replace(
          pathname + "?" + createQueryString("page", "" + (lastPage + 1)),
        ),
      );
    }
  }

  const filteredVideos = videos.filter(
    (v) =>
      (statusFilter.length === 0 || statusFilter.includes(v.status!)) &&
      (tagFilter.length === 0 || tagFilter.every((t) => v.tags.includes(t))),
  );

  const toggleStatusFilter = (value: string, checked: boolean) => {
    const newStatusFilter = checked
      ? [...statusFilter, value]
      : statusFilter.filter((s) => s !== value);
    setStatusFilter(newStatusFilter);
    if (newStatusFilter.length === 1 && newStatusFilter[0] === "needs_review") {
      router.replace(pathname + "?" + createQueryString("state", null));
    } else {
      router.replace(
        pathname + "?" + createQueryString("state", newStatusFilter.join(",")),
      );
    }
  };

  const toggleTagFilter = (value: string, checked: boolean) => {
    const newTagFilter = checked
      ? [...tagFilter, value]
      : tagFilter.filter((s) => s !== value);
    setTagFilter(newTagFilter);
    if (newTagFilter.length === 0) {
      router.replace(pathname + "?" + createQueryString("tags", null));
    } else {
      router.replace(
        pathname + "?" + createQueryString("tags", newTagFilter.join(",")),
      );
    }
  };

  return (
    <Grid columns="15rem 1fr ">
      <Flex direction="column" pl="5" p="4" gap="4">
        <Flex direction="column" gap="1">
          <Text weight="bold">Language</Text>
          <LanguagePicker langId={langId} languages={user.languages} />
        </Flex>
        {/* <Flex direction="column" gap="1">
          <Text weight="bold">Following</Text>
          {creators.map((creator) => (
            <label key={creator._id}>
              <Flex key={creator._id} align="center" gap="2">
                <SimpleAvatar name={creator.name} image={creator.image} />
                <Text>{creator.name}</Text>
              </Flex>
            </label>
          ))}
        </Flex> */}

        <Flex direction="column">
          <Text weight="bold">Topics</Text>
          <Flex direction="column">
            {allTags.map((tag, idx) => (
              <Text key={idx} as="label" size="2">
                <Flex align="center" gap="2" className="select-none">
                  <Checkbox
                    checked={tagFilter.includes(tag)}
                    onCheckedChange={(checked) =>
                      toggleTagFilter(tag, !!checked)
                    }
                  />{" "}
                  {tag}
                </Flex>
              </Text>
            ))}
          </Flex>
        </Flex>

        <Flex direction="column">
          <Text weight="bold">Status</Text>
          <Text as="label" size="2">
            <Flex align="center" gap="2" className="select-none">
              <Checkbox
                checked={statusFilter.includes("needs_review")}
                onCheckedChange={(checked) =>
                  toggleStatusFilter("needs_review", !!checked)
                }
              />{" "}
              Needs review
            </Flex>
          </Text>
          <Text as="label" size="2">
            <Flex align="center" gap="2" className="select-none">
              <Checkbox
                checked={statusFilter.includes("reviewed")}
                onCheckedChange={(checked) =>
                  toggleStatusFilter("reviewed", !!checked)
                }
              />{" "}
              Reviewed
            </Flex>
          </Text>
          <Text as="label" size="2">
            <Flex align="center" gap="2" className="select-none">
              <Checkbox
                checked={statusFilter.includes("accepted")}
                onCheckedChange={(checked) =>
                  toggleStatusFilter("accepted", !!checked)
                }
              />{" "}
              Accepted
            </Flex>
          </Text>
        </Flex>
      </Flex>

      <Container size="3" p="4">
        <CreatorPicker creatorId={creatorId} creators={creators} />
        <Flex direction="column">
          {filteredVideos.map((video, idx) => (
            <Flex key={video.videoId} direction="column">
              {idx !== 0 && <Separator orientation="horizontal" size="4" />}
              <VideoRow video={video} langId={langId} />
            </Flex>
          ))}
        </Flex>
        {filteredVideos.length === 0 && (
          <Flex p="4">
            <Text color="gray">No videos. :(</Text>
          </Flex>
        )}

        {!(page === lastPage && lastPage === 0) && (
          <Flex justify="center" mt="4" gap="4" align="center">
            <Button
              variant="soft"
              disabled={page === 0}
              onClick={() =>
                router.replace(
                  pathname +
                    "?" +
                    createQueryString("page", page === 1 ? null : "" + page),
                )
              }
            >
              <ChevronLeftIcon />
            </Button>
            <Text size="1" color="gray">
              {pageSize * page + 1}–
              {Math.min(pageSize * (page + 1), matchedVideoCount)} of{" "}
              {matchedVideoCount}
            </Text>
            <Button
              variant="soft"
              disabled={page === lastPage}
              onClick={() =>
                router.replace(
                  pathname + "?" + createQueryString("page", "" + (page + 2)),
                )
              }
            >
              <ChevronRightIcon />
            </Button>
          </Flex>
        )}
      </Container>
    </Grid>
  );
};

export default TranslatePage;
