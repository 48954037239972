"use client";
import SimpleAvatar from "@/components/SimpleAvatar";
import {Flex, Heading, Select} from "@radix-ui/themes";
import {useRouter, useSearchParams} from "next/navigation";
import {useCallback} from "react";
import styled from "styled-components";
import {setDefaultCreatorIdAction} from "./actions";

const SelectButton = styled.span`
  & button {
    box-shadow: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    height: inherit;
    padding: 2px 4px;
    margin: -2px -4px;
    &:hover {
      background-color: var(--accent-4);
    }
  }
`;

type Props = {
  creatorId: string;
  creators: {
    image: string;
    id: string;
    name: string;
  }[];
};
const CreatorPicker = ({creatorId, creators}: Props) => {
  const router = useRouter();
  const searchParams = useSearchParams();

  // Get a new searchParams string by merging the current
  // searchParams with a provided key/value pair
  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams],
  );

  const currentCreator = creators.find((c) => c.id === creatorId);

  return (
    <Flex m="3" className="isolate">
      <Heading weight="bold">
        <SelectButton>
          <Select.Root
            size="3"
            value={creatorId}
            onValueChange={(newId) => {
              router.replace("?" + createQueryString("c", newId));
              setDefaultCreatorIdAction({creatorId: newId});
            }}
          >
            <Select.Trigger>
              {currentCreator && (
                <Flex align="center" gap="2">
                  <SimpleAvatar
                    name={currentCreator.name}
                    image={currentCreator.image}
                  />
                  {currentCreator.name}
                </Flex>
              )}
            </Select.Trigger>

            <Select.Content>
              <Select.Group>
                <Select.Label>Following</Select.Label>
                {creators.map((creator) => (
                  <Select.Item
                    key={creator.id}
                    value={creator.id!}
                    style={{
                      height: "2.5rem",
                      //paddingTop: "1rem",
                      //paddingBottom: "1rem",
                    }}
                  >
                    <Flex align="center" gap="2">
                      <SimpleAvatar name={creator.name} image={creator.image} />
                      {creator.name}
                    </Flex>
                  </Select.Item>
                ))}
              </Select.Group>
            </Select.Content>
          </Select.Root>
        </SelectButton>
      </Heading>
    </Flex>
  );
};

export default CreatorPicker;
