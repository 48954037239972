import {Badge} from "@radix-ui/themes";

const TranslationStatusBadge = ({
  status,
}: {
  status: "pending" | "needs_review" | "reviewed" | "accepted";
}) => {
  switch (status) {
    case "pending":
      return (
        <Badge radius="full" color="gray">
          No translation
        </Badge>
      );
    case "needs_review":
      return (
        <Badge radius="full" color="amber">
          Needs review
        </Badge>
      );
    case "reviewed":
      return (
        <Badge radius="full" color="jade">
          Reviewed
        </Badge>
      );
    case "accepted":
      return (
        <Badge radius="full" color="indigo">
          Accepted
        </Badge>
      );
  }
};

export default TranslationStatusBadge;
