import {videoTimestamp} from "@/lib/util";
import {Box} from "@radix-ui/themes";

const Thumbnail = ({
  url,
  videoLength,
  size = "normal",
}: {
  url: string;
  videoLength: number;
  size?: "normal" | "small";
}) => {
  const width = size === "normal" ? 120 : 90;
  const height = size === "normal" ? 68 : 51;
  return (
    <Box
      position="relative"
      height={height + "px"}
      flexBasis={width + "px"}
      flexShrink="0"
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        alt="Video thumbnail"
        height={height}
        src={url}
        width={width}
        draggable={false}
        style={{width, height}}
      />
      <Box
        position="absolute"
        right="0"
        bottom="0"
        m="1"
        px="1"
        className={
          "bg-[rgba(0,0,0,0.8)] font-['Roboto','Noto',sans-serif] text-xs text-white"
        }
      >
        {videoTimestamp(videoLength)}
      </Box>
    </Box>
  );
};

export default Thumbnail;
