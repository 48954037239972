import RelativeTime from "@/components/RelativeTime";
import Thumbnail from "@/components/Thumbnail";
import TranslationStatusBadge from "@/components/TranslationStatusBadge";
import {Badge, Button, Flex, Link, Text} from "@radix-ui/themes";

const VideoRow = ({
  video,
  langId,
}: {
  video: {
    videoId: string;
    title: string;
    publishedAt: Date;
    thumbnailUrl: string;
    videoLength: number;
    langId: string | null;
    status: "needs_review" | "reviewed" | "accepted" | "pending" | null;
    tags: string[];
    draftId: string | null;
    draftCreatedAt: Date | null;
  };
  langId: string;
}) => {
  return (
    <Flex
      key={video.videoId}
      align="center"
      className="relative isolate hover:bg-[color:var(--gray-a2)]"
      gap="2"
      p="2"
    >
      <Flex minWidth="120px">
        <Thumbnail url={video.thumbnailUrl} videoLength={video.videoLength} />
      </Flex>
      <Flex direction="column" align="start" gap="1" flexGrow="1">
        <Flex>
          <TranslationStatusBadge status={video.status!} />
        </Flex>
        <Text size="2">{video.title}</Text>
        <Flex gap="1">
          {video.tags.map((tag, idx) => (
            <Badge key={idx} color="gray">
              {tag}
            </Badge>
          ))}
        </Flex>
      </Flex>

      <Link
        href={`/video/${video.videoId}/${langId}`}
        className="absolute left-0 h-full w-full"
      />

      {video.draftId != null && video.draftCreatedAt != null ? (
        <Flex direction="column" align="end" justify="between" className="z-10">
          <Link
            href={`/video/${
              video.videoId
            }/${langId}/transcript/${video.draftId}`}
          >
            <Button>Continue reviewing</Button>{" "}
          </Link>
          <Text size="1" color="gray">
            You created a draft review of this translation{" "}
            <RelativeTime time={video.draftCreatedAt.toString()} on={true} />.
          </Text>
        </Flex>
      ) : (
        video.status === "needs_review" && (
          <Flex
            direction="column"
            align="end"
            justify="between"
            className="z-10"
          >
            <Link href={`/video/${video.videoId}/${langId}/transcript/current`}>
              <Button variant="soft">Start&nbsp;reviewing</Button>
            </Link>
          </Flex>
        )
      )}
    </Flex>
  );
};

export default VideoRow;
