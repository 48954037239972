import {Tooltip} from "@radix-ui/themes";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const RelativeTime = ({
  time,
  on = false,
}: {
  time: string;
  on?: boolean;
}) => {
  // TODO(eater): Figure out how to localize on server
  return (
    <Tooltip
      content={new Date(time).toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      })}
    >
      <span>
        {Date.now() - new Date(time).getTime() >
        1000 /*ms*/ * 60 /*s*/ * 60 /*min*/ * 24 /*hr*/ * 10 /*days*/
          ? (on ? "on " : "") +
            new Date(time).toLocaleString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : dayjs(time).fromNow()}
      </span>
    </Tooltip>
  );
};

export default RelativeTime;
