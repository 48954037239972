"use client";
import {LANGUAGE_SUPPORT, formatLanguage} from "@/lib/util";
import {Flex, Select} from "@radix-ui/themes";
import {useRouter, useSearchParams} from "next/navigation";
import {useCallback} from "react";
import styled from "styled-components";
import {setDefaultLangIdAction} from "./actions";

const SelectButton = styled.span`
  & button {
    box-shadow: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    height: inherit;
    padding: 2px 4px;
    margin: -2px -4px;
    &:hover {
      background-color: var(--accent-4);
    }
  }
`;
type Props = {langId: string; languages: string[]};
const LanguagePicker = ({langId, languages}: Props) => {
  const router = useRouter();
  const searchParams = useSearchParams();

  // Get a new searchParams string by merging the current
  // searchParams with a provided key/value pair
  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams],
  );

  return (
    <Flex className="isolate">
      <SelectButton>
        <Select.Root
          value={langId}
          onValueChange={(newLang) => {
            if (newLang === "configure") {
              router.push("/creators");
            } else {
              router.replace("?" + createQueryString("lang", newLang));
              setDefaultLangIdAction({langId: newLang});
            }
          }}
        >
          <Select.Trigger>{formatLanguage(langId)}</Select.Trigger>

          <Select.Content>
            {languages
              .sort(
                (a, b) =>
                  LANGUAGE_SUPPORT.find(
                    (ls) => ls.langId === a,
                  )?.display.localeCompare(
                    LANGUAGE_SUPPORT.find((ls) => ls.langId === b)?.display ??
                      "",
                  ) || 0,
              )
              .map((id) => (
                <Select.Item key={id} value={id}>
                  {formatLanguage(id)}
                </Select.Item>
              ))}
            <Select.Separator />
            <Select.Item value="configure">Choose languages…</Select.Item>
          </Select.Content>
        </Select.Root>
      </SelectButton>
    </Flex>
  );
};

export default LanguagePicker;
