import {Avatar} from "@radix-ui/themes";

interface SimpleAvatarInterface {
  name: string;
  image: string | null;
}

const SimpleAvatar = ({name, image}: SimpleAvatarInterface) => {
  const fallback = (name || "?")
    .split(/ /)
    .map((s: string) => s[0])
    .join("")
    .substring(0, 2);

  return (
    <Avatar
      size="2"
      src={image || undefined}
      fallback={fallback}
      radius="full"
    />
  );
};

export default SimpleAvatar;
